import { Controller } from "stimulus"
import DriveFilesApi from "@api/DriveFilesApi";

export default class DocumentController extends Controller {

  async sendRequestAccess()  {
    try {
      const docIdElement = document.querySelector<HTMLInputElement>('#doc_id')
      if (!docIdElement) return

      const data = await DriveFilesApi.requestAccessToDocument<{
        noticeMsg: string
      }>({ id: docIdElement.value })

      avv_dialog({snackMessage: data.noticeMsg, snackStyle: "notice"})
    } catch (error) {
      avv_dialog({snackMessage: (error as { message: string }).message, snackStyle: "error"})
    }
  }

  assignDocId(e: Event){
    if (!e.target) return false

    const docIdElement = (e.target as HTMLElement).closest("[data-doc-id]")
    const docIdValue = docIdElement?.getAttribute("data-doc-id")
    document.getElementById("doc_id")?.setAttribute("value", docIdValue)
  }
}
