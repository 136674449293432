import consumer from "../channels/consumer"
import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";

export interface BulkJobsController extends ExposeStimulus<typeof BulkJobsController> {}
export class BulkJobsController extends StimulusControllerBase {
  static values = {
    id: String
  }

  static targets = [
    'text', 'successBar', 'errorBar'
  ] as const

  connect () {
    const _this = this

    consumer.subscriptions.create({
      channel: 'BulkJobs::SetChannel',
      id: this.idValue
    }, {
      received(response: any) {
        const { jobs_total, jobs_success, jobs_error } = response.data
        _this._updateBar(jobs_total, jobs_success, jobs_error)

        if (jobs_total <= jobs_success + jobs_error) {
          window.location.reload()
        }
      }
    })
  }

  _updateBar (jobs_total: number, jobs_success: number, jobs_error: number) {
    const ratioSuccess = 100.0 * jobs_success / jobs_total
    const ratioError = 100.0 * jobs_error / jobs_total

    this.successBarTarget.style.width = `${ratioSuccess}%`
    this.errorBarTarget.style.width = `${ratioError}%`

    this.textTarget.innerText = `${jobs_success + jobs_error} out of ${jobs_total}`
  }
}

export default BulkJobsController
