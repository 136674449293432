import {ascendantNodeByTag, warnMissingElements} from "../global.utils";
import CustomConditionDialog from "../features/custom_reports/CustomConditionDialog.vue"
import {onDomContentLoaded} from "../features/dom_utils";
import Utils from "../features/utils";
const axios = Utils.axios
import AvvParser from "../features/parser";
import type {Component} from "vue";
import { createVueApp } from "../features/vue";
import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";


export interface TemplatePackItemsController extends ExposeStimulus<typeof TemplatePackItemsController, HTMLElement> {}
export class TemplatePackItemsController extends StimulusControllerBase {
  static targets = ["newItemButton", "table"] as const
  static values = {
    id: String,
    importFolderUrl: String
  }

  connect(){
    this.listenOnDragEnd()
    this.listenOnItemRemoval()
    this.listenOnConditionBuilderClose()
    onDomContentLoaded(this.initConditionBuilders.bind(this))
  }

  async fetchAttributes(){
    return new Promise<string[]>(async resolve => {
      const template_ids = this.template_ids
      const response = await axios.post<{attributes: string[]}>('/template_packs/attributes_by_templates.json', {template_ids})
      resolve(response.data.attributes.map(AvvParser.decode))
    })
  }

  async initConditionBuilders(){
    const attributes = await this.fetchAttributes()
    const elements = Array.from(document.querySelectorAll<HTMLElement>('.template-pack-item-condition:not(.template)'))
    elements.forEach(el => void this.initConditionBuilder(el, attributes))
  }

  async initConditionBuilder(element: HTMLElement, _attributes?: string[]){
    const attributes = _attributes ?? await this.fetchAttributes()
    if(!element.classList.contains('template-pack-item-condition')) element = element.querySelector('.template-pack-item-condition') as HTMLElement
    const astString = (element.nextElementSibling as HTMLInputElement).value || Ast.DEFAULT_AST_STRING
    const parsedAst = Ast.parse(astString)
    if(!parsedAst) return
    const app = createVueApp(CustomConditionDialog as Component, { astString: astString, attributes: attributes, values: Ast.traverse(parsedAst).values, type: 'attributes', populateFormInput: true, formInputSelector: '.template-pack-item-condition' })
    app.mount(element)
  }

  showCondition(e: PointerEvent | null, button?: HTMLElement) {
    const buttonElement = (button ?? e?.target) as HTMLElement
    const row = buttonElement.closest('tr')!.nextElementSibling!
    row.classList.toggle('hidden')
  }

  listenOnDragEnd(){
    document.addEventListener('end', () => {
      setTimeout(() => {
        this.moveHiddenRows()
        this.setOrderForAll()
      }, 0)
    })
  }

  listenOnItemRemoval(){
    document.addEventListener('nested_item_removed', () => {
      setTimeout(() => this.setOrderForAll(), 0)
      this.handleEmptyRow()
    })
  }

  listenOnConditionBuilderClose(){
    document.addEventListener('close-condition-builder', (e) => {
      const ev = e as CustomEvent
      const button = ev.detail.wrapper.closest('tr').previousElementSibling.querySelector('.avv-button')
      this.showCondition(null, button)
    })
  }

  setOrderForAll(){
    this.items.visible.forEach((child, index) => this.handleOrderNumber(child, false, index + 1))
  }

  moveHiddenRows(){
    this.items.visible.forEach((row) => {
      const templateId = this.itemId(row.querySelector('avv-select'))
      if(typeof templateId !== 'string') return
      if(!row.nextElementSibling?.id.includes(templateId)){
        const hiddenRow = document.getElementById(`template_pack_item_${templateId}_condition`)
        if(!hiddenRow) return warnMissingElements()
        hiddenRow.remove()
        row.after(hiddenRow)
      }
    })
  }

  async handleAddButtonClick(value: number | MouseEvent){
    const template = document.getElementById('template_pack_item_template') as HTMLElement
    const templateCond = document.getElementById('template_pack_item_condition_template') as HTMLElement
    const newItem = template.cloneNode(true) as HTMLElement
    const newItemCond = templateCond.cloneNode(true) as HTMLElement
    await this.initConditionBuilder(newItemCond)
    this.handleClassNameAndId(newItem)
    this.handleOrderNumber(newItem)
    if(typeof value === 'number') this.handleValue(newItem, value)
    this.appendRow(newItem)
    this.appendRow(newItemCond)
  }

  appendRow(element: HTMLElement) {
    const tbody = this.tableTarget.querySelector('tbody') as HTMLElement
    tbody.appendChild(element)
    this.handleEmptyRow()
  }

  handleEmptyRow(){
    const isEmptyTable = !this.items.visible.length
    const emptyRow = this.tableTarget.querySelector('.empty-table-row') as HTMLElement
    if(!emptyRow) return warnMissingElements()
    emptyRow.classList[isEmptyTable ? 'remove' : 'add']('hidden')
  }

  handleClassNameAndId(element: HTMLElement){
    element.classList.remove('hidden')
    element.id += `_template`
    const selectInput = element.querySelector('avv-select input[type="hidden"]')
    const orderInput = element.querySelector('#order') ?? element.querySelector('.order-input')
    const destroyInput = element.querySelector("input[name*='_destroy']")
    const conditionInput = element.querySelector("input[name*='condition']")

    const userIdInput = element.querySelector('[name="user_id"]')
    if(selectInput) selectInput.setAttribute("name", `template_pack[template_pack_items_attributes][${this.activeItemsCount}][template_id]`)
    if(orderInput) orderInput.setAttribute("name", `template_pack[template_pack_items_attributes][${this.activeItemsCount}][order]`)
    if(destroyInput) destroyInput.setAttribute("name", `template_pack[template_pack_items_attributes][${this.activeItemsCount}][_destroy]`)
    if(userIdInput) userIdInput.setAttribute("name", `template_pack[template_pack_items_attributes][${this.activeItemsCount}][user_id]`)
    if(conditionInput) conditionInput.setAttribute("name", `template_pack[template_pack_items_attributes][${this.activeItemsCount}][condition]`)
  }

  handleOrderNumber(element: HTMLElement, is_new = true, index?: number){
    const orderSpan = element.querySelector('.order')
    const orderInput = (element.querySelector('#order') ?? element.querySelector('.order-input')) as HTMLInputElement
    const select = element.querySelector('avv-select') as HTMLElement
    if(!orderSpan || !orderInput || !select) {
      return warnMissingElements()
    }
    const newOrderValue = this.visibleItemsCount + 1
    const computedOrderValue = this.ordered_items_ids.indexOf(this.itemId(select)) + 1
    const value = index ?? (is_new ? newOrderValue : computedOrderValue)
    orderSpan.textContent = String(value)
    orderInput.value = String(value)
  }

  handleValue(element: HTMLElement, value: number){
    const select = element.querySelector('avv-select') as HTMLElement
    if(!select) return warnMissingElements()
    const input = select.querySelector('input[type="hidden"]') as HTMLInputElement
    input.value = String(value)
  }

  itemId(select: HTMLElement){
    const row = ascendantNodeByTag('tr', select) as HTMLElement
    const splited = row?.id.split('_')
    return splited[splited.length - 1]
  }

  get activeItemsCount() {
    return this.items.active.length
  }

  get visibleItemsCount() {
    return this.items.visible.length
  }

  get items(){
    const hiddenTemplateClasses = ['hidden', 'empty-table-row']
    const visibleTemplates = row => {
      const hasHiddenClasses = hiddenTemplateClasses.some(klass => row.classList.contains(klass))
      const isDisplayNone = row.style.display === 'none'
      return !hasHiddenClasses && !isDisplayNone
    }
    const activeTemplates = row => {
      const hasHiddenClasses = hiddenTemplateClasses.some(klass => row.classList.contains(klass))
      return !hasHiddenClasses
    }
    const children = Array.from(this.tableTarget.querySelector('tbody').children)
    return {
      "active": children.filter(activeTemplates),
      "visible": children.filter(visibleTemplates)
    }
  }

  async import_template_from_folder(){
    const folder_id = document.querySelector('select[name="template_pack[folder_id]"]').value
    const url = this.importFolderUrlValue
    const data = { folder_id: folder_id }
    const response = await axios.post(url, data)
    const success = response.status === 200
    if(success){
      response.data['template_ids'].forEach(id => this.handleAddButtonClick(id))
    } else {
      avv_dialog({snackMessage: response.data.error, snackStyle: 'error'})
    }
  }

  get ordered_items_ids(){
    const rows = this.items.visible
    return rows.map(row => this.itemId(row.querySelector('avv-select'))).filter(e => e)
  }

  get template_ids(){
    const rows = this.items.visible
    return rows.map(row => row.querySelector('avv-select input[type="hidden"]').value)
  }

  get template_pack_id(){
    return this.idValue
  }
}

export default TemplatePackItemsController