import Utils from '../features/utils'
import { IsHTMLInputElement } from '../features/dom_utils'
import { type ExposeStimulus, StimulusControllerBase } from './base_controller'

export interface ArchivingController
  extends ExposeStimulus<typeof ArchivingController> {}
export class ArchivingController extends StimulusControllerBase {
  static targets = ['item', 'folder']
  getSelectedItems(selector: string) {
    const selectedItems = Array.from(document.querySelectorAll(selector))
      .filter(
        (checkbox) =>
          IsHTMLInputElement(checkbox) &&
          checkbox.checked &&
          checkbox.value !== 'on'
      )
      .map((checkbox) => IsHTMLInputElement(checkbox) && checkbox.value)
    return selectedItems
  }

  async activate() {
    const controller = sidebarStore.state.controller
    const selectedFolderIds = this.hasFolderTarget
      ? [this.folderTarget.id]
      : this.getSelectedItems('.folders-item-check-input')
    const selectedItemIds = this.hasItemTarget
      ? [this.itemTarget.id]
      : this.getSelectedItems(
        `.${controller?.replace('_','-')}-item-check-input`)
      const url = sidebarStore.state.bulkActionUrl
      if (!url) return console.warn('Url not specified')
      const requestData = {
        bulk_action: 'activate',
        permanent_delete: false,
        item_ids: selectedItemIds,
        folder_ids: selectedFolderIds
      }
    try {
      const response = await Utils.axios.post(url, requestData)
      const {not_authorized, notice} = response.data
      
      if(not_authorized) return avv_toast({
        message: localizeText('shared.archive_dialog.notice.not_authorized'),
        type: 'error'
      })
      if (response.status.toString().startsWith('2')) {
        avv_toast({
          message: notice,
          type: 'default'
        })
        setTimeout(() => window.location.reload(), 300)
      } else {
        avv_toast({
          message: localizeText('shared.archive_dialog.notice.not_authorized'),
          type: 'error'
        })
      }
    } catch (e: any) {
      avv_toast({
        message: e.message,
        type: 'error'
      })
    }
  }

  async archiveMultipleFolders() {
    const selectedItemIds = sidebarStore.state.itemIdsToArchive
    const selectedFolderIds = sidebarStore.state.folderIdsToArchive
    let snackMessage = ''
    let error = false
    const url = sidebarStore.state.bulkActionUrl
    if (!url) return console.warn('Url not specified')
    const requestData = {
      bulk_action: 'archive',
      permanent_delete: false,
      item_ids: selectedItemIds,
      folder_ids: selectedFolderIds
    }
    try {
      const response = await Utils.axios.post(url, requestData)

      sidebarStore.commit('TOGGLE_ARCHIVE_MODAL_OPEN')
      if (response.status.toString().startsWith('2')) {
        const { non_empty_folders, not_authorized, notice } = response.data
        sidebarStore.commit('SET_FOLDERS_TO_ARCHIVE', {
          folders: non_empty_folders
        })
        if (non_empty_folders) {
          sidebarStore.commit('TOGGLE_CONFIRM_ARCHIVE_MODAL_OPEN')
          return
        } else if (not_authorized) {
          snackMessage = notice
          error = true
        } else {
          snackMessage = notice
        }
        avv_toast({
          message: snackMessage,
          type: error ? 'error' : 'default'
        })
        if (!non_empty_folders && !not_authorized)
          setTimeout(() => window.location.reload(), 300)
      }
    } catch (e: any) {
      avv_toast({
        message: e.message,
        type: 'error'
      })
    }
  }

  showModal() {
    const controller = sidebarStore.state.controller
    const archiveFromSidebar = this.hasFolderTarget || this.hasItemTarget
    const selectedFolderIds = this.hasFolderTarget
      ? [this.folderTarget.id]
      : archiveFromSidebar
        ? []
        : this.getSelectedItems('.folders-item-check-input')
    const selectedItemIds = this.hasItemTarget
      ? [this.itemTarget.id]
      : archiveFromSidebar
        ? []
        : this.getSelectedItems(
          `.${controller?.replace('_','-')}-item-check-input`)

    if (!selectedFolderIds.length && !selectedItemIds.length) return

    sidebarStore.commit('SET_ARCHIVE_ITEMS', {
      folderIds: selectedFolderIds,
      itemIds: selectedItemIds
    })
    sidebarStore.commit('TOGGLE_ARCHIVE_MODAL_OPEN')
  }

  showModalFromSidebar() {
    sidebarStore.commit('SET_ARCHIVE_FROM_SIDEBAR', true)
    this.showModal()
  }
}
export default ArchivingController
