import {download_report} from "../features/custom_reports/utils";
import {type ExposeStimulus, StimulusControllerBase} from "./base_controller";

export interface CustomReportsController extends ExposeStimulus<typeof CustomReportsController> {}
export class CustomReportsController extends StimulusControllerBase {
  static values = {
    id: Number
  }

  download(){
    download_report(this.idValue)
  }
}

export default CustomReportsController
