import { Controller } from 'stimulus'

import { createApp, type Component } from 'vue'
import Search from '../features/search/Search.vue'
import {
  encodeFilters,
  decodeFilters,
  type BadgeConfig,
  reactiveData,
  decodeUTF8Base64
} from '../features/search/shared'

type SearchResponse = {
  content_html: string
  pagination_html: string
  count: number
}

export default class SearchController extends Controller {
  static targets = [
    'pagination',
    'count',
    'input',
    'spinner',
    'clear',
    'content'
  ]

  searchData = reactiveData()
  searchConfig?: BadgeConfig
  searchHeight?: number

  declare searchEndpoint: string

  declare readonly contentTarget: HTMLElement
  declare readonly inputTarget: HTMLInputElement
  declare readonly clearTarget: HTMLElement

  declare readonly hasPaginationTarget: boolean
  declare readonly paginationTarget: HTMLElement

  declare readonly hasSpinnerTarget: boolean
  declare readonly spinnerTarget: HTMLElement

  declare readonly hasCountTarget: boolean
  declare readonly countTarget: HTMLElement

  connect() {
    this.searchEndpoint = this.data.get('endpoint') as string

    this.loadConfig()
    this.readDataFromParams()
    this.decorateSortHeaders()

    // Create badge app if any sorting headers or filtering points exist
    if (this.searchConfig) {
      const element = this.inputTarget.parentElement as HTMLElement

      const app = createApp(Search as Component, {
        data: this.searchData,
        config: this.searchConfig,
        onChange: this.fetchData.bind(this)
      })
      app.mount(element)

      // Track badge group size
      new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          this.searchHeight = entry.contentRect.height
          this.resizeTable()
        })
      }).observe(element)
    }
  }

  sort(event: Event) {
    event.preventDefault()

    const eventTarget = event.currentTarget as HTMLElement
    const sortKey = eventTarget.dataset.sortKey

    const currentSortKey = this.searchData.sort.key

    if (sortKey) {
      this.searchData.sort.key = sortKey
      this.searchData.sort.asc =
        sortKey === currentSortKey && !this.searchData.sort.asc

      this.fetchData()
    }
  }

  clear(event: Event) {
    event.preventDefault()

    this.searchData.search = this.inputTarget.value = ''

    this.fetchData()
  }

  submit(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault()

      this.searchData.search = this.inputTarget.value

      this.fetchData()
    }
  }

  hideClasses() {
    return ['hide', 'hidden']
  }

  decorateSortHeaders() {
    const decorateElement = (element: Element) => {
      element.classList.add('cursor-pointer')
      element.addEventListener('click', this.sort.bind(this))
    }

    this.element.querySelectorAll('[data-sort-key]').forEach(decorateElement)
  }

  fetchData() {
    void this.fetchDataAsync()
  }

  async fetchDataAsync() {
    if (this.searchData.freeze) return

    // Update browser tab URL to new params
    const params = this.writeDataToParams()

    // Fetch new data
    this.searchData.freeze = true

    if (this.hasSpinnerTarget)
      this.contentTarget.innerHTML = this.spinnerTarget.innerHTML
    if (this.hasPaginationTarget) this.paginationTarget.innerHTML = ''
    if (this.hasCountTarget) this.countTarget.innerHTML = '0'

    try {
      const response = await fetch(
        `${this.searchEndpoint}?${params.toString()}`,
        {
          method: 'GET',
          credentials: 'include'
        }
      )

      if (!response.ok) throw Error(response.statusText)
      else {
        const data = (await response.json()) as SearchResponse

        this.contentTarget.innerHTML = data.content_html
        if (this.hasPaginationTarget)
          this.paginationTarget.innerHTML = data.pagination_html
        if (this.hasCountTarget) this.countTarget.innerHTML = String(data.count)

        this.emit()
        this.decorateSortHeaders()
        this.resizeTable()

        if (this.searchData.search.length === 0) {
          this.hideClasses().forEach((c) => this.clearTarget.classList.add(c))
        } else {
          this.hideClasses().forEach((c) =>
            this.clearTarget.classList.remove(c)
          )
        }

        this.searchData.freeze = false
      }
    } catch (e: unknown) {
      this.searchData.freeze = false
    }
  }

  resizeTable() {
    if (!this.searchHeight || !this.searchConfig) return

    const table =
      this.contentTarget.querySelector<HTMLTableElement>('.collection-table')

    if (table) {
      table.classList.add('with-advanced-search')
      table.style.setProperty(
        '--avv-advanced-search-height',
        `${this.searchHeight}px`
      )
    }
  }

  emit() {
    window.dispatchEvent(
      new CustomEvent('mainContentChange', {
        detail: { element: this.contentTarget }
      })
    )
  }

  readDataFromParams() {
    const params = new URLSearchParams(window.location.search)
    if (params.has('sort_by')) {
      this.searchData.sort.key = params.get('sort_by') ?? ''
      this.searchData.sort.asc = params.get('sort_direction') === 'asc'
    }

    if (params.has('search')) {
      this.searchData.search = params.get('search') ?? ''
    }

    if (params.has('filter_by') && this.searchConfig) {
      this.searchData.filters = decodeFilters(
        params.get('filter_by') ?? '',
        this.searchConfig
      )
    }
  }

  writeDataToParams() {
    const params = new URLSearchParams(window.location.search)

    // Filter by name
    const currentFilter = this.searchData.search
    if (currentFilter) {
      params.set('search', currentFilter)
      params.set('first_search', 'true')
    } else {
      params.delete('search')
      params.delete('first_search')
    }

    // Sort
    const currentSortKey = this.searchData.sort.key
    if (currentSortKey) {
      params.set('sort_by', currentSortKey)
      params.set('sort_direction', this.searchData.sort.asc ? 'asc' : 'desc')
    } else {
      params.delete('sort_by')
      params.delete('sort_direction')
    }

    // Filters
    const currentFilters = this.searchData.filters
    if (Object.keys(currentFilters).length > 0) {
      params.set('filter_by', encodeFilters(currentFilters))
    } else {
      params.delete('filter_by')
    }

    // Push update into history to change the URL in the bar
    window.history.pushState(
      {},
      '',
      `${window.location.origin}${
        window.location.pathname
      }?${params.toString()}`
    )

    return params
  }

  // Try to load search config
  loadConfig() {
    const config = this.data.get('config')

    if (typeof config === 'string' && config) {
      try {
        this.searchConfig = JSON.parse(decodeUTF8Base64(config))
      } catch (e: unknown) {
        console.error('Search settings were not able to be read!')
      }
    }
  }
}
