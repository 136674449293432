import ExportApi from '@api/TemplateVersions/ExportApi'
import type { ExposeStimulus } from './base_controller'
import BulkActionsController from './bulk_actions_controller'
import axios from 'axios'
import { check } from '@avvoka/shared'
import { IsHTMLElement } from '../features/dom_utils'

export interface TemplateBulkActionsController
  extends ExposeStimulus<typeof TemplateBulkActionsController> {}
export class TemplateBulkActionsController extends BulkActionsController {
  static targets = ['folderIdInput'] as const

  addToFolder(event: Event) {
    if (!IsHTMLElement(event.currentTarget)) return
    const folderId = event.currentTarget.dataset.folderId
    if (folderId) this.folderIdInputTarget.value = folderId

    this.submitForm('add_to_folder')
  }

  async moveItemToFolder(event: Event) {
    const folderId = (event.currentTarget as HTMLElement).dataset.folderId
    const folderIds = this.getSelectedTemplateItems('.folders-item-check-input')
    const templateIds = this.getSelectedTemplateItems(
      '.templates-item-check-input'
    )
    const url = '/templates/set_folder_for_multiple'
    const response = await axios
      .post(url, {
        folder_id: folderId,
        template_ids: templateIds,
        folder_ids: folderIds
      })
      .catch((e: Error) => {
        avv_toast({
          type: 'error',
          message: e.message
        })
      })
    if (response.status) {
      window.avv_toast({
        message: localizeText('templates.actions.move.success'),
        type: 'default'
      })
      window.location.href = `/templates${
        Number(folderId) === 0 ? '' : `?folder_id=${folderId as string}`
      }`
    }
  }

  getSelectedTemplateItems(selector: string, trows?: boolean) {
    const checkboxes = Array.from(document.querySelectorAll(selector))
    const selectedCheckboxes = checkboxes.filter(
      (checkbox) => (checkbox as HTMLInputElement).checked
    )
    const selectedTrows = trows
      ? selectedCheckboxes.map((checkbox) => checkbox.closest('tr'))
      : null
    return (
      selectedTrows ??
      selectedCheckboxes
        .map((c) => (c as HTMLInputElement).value)
        .filter((val) => val !== 'on')
    )
  }

  async templates_in_folders(
    folder_ids: string[] | (HTMLTableRowElement | null)[]
  ): Promise<string[]> {
    const url = `/templates/folder_template_version_ids`
    const requestData = { folder_ids: folder_ids }
    const response = await axios.post(url, requestData)
    if (response.status === 200) {
      return (response.data as { template_ids: string[] }).template_ids?.map(
        (id) => String(id)
      )
    }
    return []
  }

  async restoreFolders() {
    const folderIds = this.getSelectedTemplateItems('.folders-item-check-input')
    const url = '/folders/restore_folders'
    const response = await axios.post(url, { folder_ids: folderIds })
    if (response.status.toString().startsWith('2')) {
      window.avv_toast({
        message: localizeText('templates.folders.restored_success'),
        type: 'default'
      })
      window.location.href = '/templates'
    }
  }

  removeFromFolder() {
    this.submitForm('remove_from_folder')
  }

  gsubText() {
    this.submitForm('gsub_text')
  }

  async exportAvvoka() {
    const selectedInputs = Array.from<HTMLInputElement>(
      document.querySelectorAll(
        'input[type="checkbox"][name="template_ids[]"]:checked'
      )
    )
    const folderIds = this.getSelectedTemplateItems('.folders-item-check-input')
    const templateVersionIdsInFolders =
      await this.templates_in_folders(folderIds)
    const templateVersionIdsInputs = selectedInputs.map(
      (input) => input.dataset.templateVersionId
    )
    let templateVersionIds: (string | undefined)[] = []

    if (
      templateVersionIdsInputs.length &&
      templateVersionIdsInFolders?.length
    ) {
      templateVersionIds = templateVersionIdsInputs.concat(
        templateVersionIdsInFolders
      )
    } else if (templateVersionIdsInputs.length) {
      templateVersionIds = templateVersionIdsInputs
    } else if (templateVersionIdsInFolders.length) {
      templateVersionIds = templateVersionIdsInFolders
    }

    window.avv_download(ExportApi.create.path(), {
      template_version_ids: templateVersionIds,
      formats: 'document_avvoka'
    })
  }

  importAvvoka(event: PointerEvent) {
    event.preventDefault()

    const target = event.target as HTMLElement
    const element = target.closest('[data-profile]') as HTMLAnchorElement

    avv_upload({
      title: localizeText('template.docx_upload.title'),
      description: localizeText('template.docx_upload.desc'),
      path: '/template_versions/import',
      data: {
        profile_id: element.dataset.profile,
        folder_id: element.dataset.folderId
      },
      accept: ['.zip', '.avvoka', '.docx'],
      multiple: true
    }).then(() => {
      setTimeout(() => window.location.reload(), 2000)
    })
  }
}

export default TemplateBulkActionsController
