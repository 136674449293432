import { LoadingHelpers } from '../features/loading'
import { IsHTMLElement, onDomContentLoaded } from '../features/dom_utils'
import { resolveQuestionnaireConflicts } from '../features/questionnaire_merge/utils'
import Utils from '../features/utils'
import { type ExposeStimulus, StimulusControllerBase } from './base_controller'
const axios = Utils.axios

export interface TemplatePacksController
  extends ExposeStimulus<typeof TemplatePacksController> {}
export class TemplatePacksController extends StimulusControllerBase {
  static values = { templatePackId: String }

  connect() {
    document.addEventListener('apply-condition-builder', (e) => {
      const { astString, wrapper } = e.detail
      const humanAst = window.Ast.astStringToHumanReadable(astString)
      const display = wrapper
        .closest('tr')
        .previousElementSibling.querySelector("[id*='generation-condition']")
      display.innerText = humanAst
      avv_dialog({
        snackMessage: 'Condition successfully applied',
        snackStyle: 'notice'
      })
    })

    onDomContentLoaded(() => {
      const conditionDisplays = document.querySelectorAll<HTMLElement>(
        "[id*='generation-condition']"
      )
      conditionDisplays.forEach((display) => {
        const ast = window.Ast.astStringToHumanReadable(display.innerText) ?? ''
        if (display.innerText) display.innerText = ast
      })
    })
  }

  checkConflicts(event: MouseEvent){
    if(!IsHTMLElement(event.target)) return
    const template_pack_id = event.target.dataset.templatePackId
    const callback = (value: boolean)=> {
      if(value){
        LoadingHelpers.startLoading()
        window.location.href = `/template_packs/${template_pack_id}/launch`
      } 
    }
    this.hasConflicts(template_pack_id, callback).catch(e =>{
      avv_toast({
        message: e,
        type: 'error',
      })
    })
  }

  async hasConflicts(template_pack_id: string | undefined, callback: (value: boolean) => void){
    const response = await axios.get<{ conflicts: Record<string, unknown> }>(
      `/template_packs/${template_pack_id}/conflicts`
    )

    const hasConflicts = Object.keys(response.data.conflicts).length > 0
    if (hasConflicts) {
      const confirmMessage = localizeText('template_packs.dialog.has_conflicts')
      avv_dialog({
        confirmMessage,
        confirmTitle: localizeText('template_packs.dialog.unresolved_conflicts'),
        confirmCallback: callback
      })
    } else {
      callback(true)
    }
  }

  save() {
    this.fillQuestionnaireInput()
    this.savePackInfo()
  }

  fillQuestionnaireInput() {
    if (!window.qStore) return
    const questionnaireInput = document.getElementById(
      'template_pack_questionnaire'
    ) as HTMLInputElement
    questionnaireInput.value = this.questionnaire
  }

  get questionnaire() {
    window.qStore.getters.nonDeletedQuestions.forEach((q) => {
      if (q.cond == 'null') q.cond = undefined
    })
    const avvFormat = resolveQuestionnaireConflicts(
      window.qStore.getters.nonDeletedQuestions,
      this.resolutions,
      window.templatePackQuestionnaireData.conflicts,
      window.templatePackQuestionnaireData.new_questionnaires
    )
    return avvFormat.substring(
      avvFormat.indexOf('.questionnaire'),
      avvFormat.lastIndexOf('.end questionnaire') + '.end questionnaire'.length
    )
  }

  get resolutions() {
    const resolutionsInput = document.getElementById(
      'template_pack_resolutions'
    ) as HTMLInputElement
    const resolutionsHash = JSON.parse(resolutionsInput.value || '{}') as Backend.Questionnaire.ResolvedValues
    // Remove invalid resolutions, caused by resolutions being a string instead of an object
    const validAttributes = window.qStore.getters.nonDeletedQuestions.map(q => q.att).filter(e => e)
    const invalidResolutions = Object.keys(resolutionsHash).filter(k => !validAttributes.includes(k))
    invalidResolutions.forEach(k => delete resolutionsHash[k])
    resolutionsInput.value = JSON.stringify(resolutionsHash)
    // this can be removed once the invalid resolutions are no longer present
    return resolutionsHash
  }

  savePackInfo() {
    document.forms[0].submit()
  }
}

export default TemplatePacksController
