import { Controller } from 'stimulus'
import consumer from '../channels/consumer'
import { useUserStore } from '@stores/generic/user.store'
import { getActivePinia } from 'pinia'
import NotificationApi from '@api/NotificationApi'

export default class extends Controller {
  channel: ActionCable.Channel | undefined
  userStore = useUserStore(getActivePinia())
  async connect() {
    await this.userStore.hydrate({}, ['id', 'display_name'])
    const user_id = this.userStore.id
    this.channel = consumer.subscriptions.create(
      { channel: 'NotificationChannel', room: user_id },
      {
        received: this.handleReceived.bind(this)
      }
    )
  }

  disconnect() {
    if (this.channel) this.channel.unsubscribe()
  }

  handleReceived(data: {
    error: string
    id: number
    notification_type: 'error' | 'success' | 'alert' | 'notice'
  }) {
    window.avv_dialog({
      snackStyle: data.notification_type,
      snackMessage: data.error
    })
    void NotificationApi.removeNotification({ id: data.id })
  }
}
