import { stopAndPrevent } from '../../features/_abstract/utils/event'
import { type ExposeStimulus, StimulusControllerBase } from '../base_controller'

import FormDialog from '../../features/forms/FormDialog.vue'
import { useOrganisationStore } from '@stores/generic/organisation.store'
import { getActivePinia } from 'pinia'
import Utils from '../../features/utils'

export interface RowsController extends ExposeStimulus<typeof FormController> {}
export class FormController extends StimulusControllerBase {
  create(e: PointerEvent) {
    stopAndPrevent(e)

    void this.#hydrateOrganisation().then(() => {
      window.avv_dialog({
        vueDialog: FormDialog,
        callback: (v: boolean) => {
          if (v) window.location.reload()
        },
        props: {
          edit: true
        }
      })
    })
  }

  view(e: PointerEvent) {
    stopAndPrevent(e)

    const formId = this.#getAttribute(e, 'data-form-id')

    void this.#hydrateOrganisation().then(() => {
      window.avv_dialog({
        vueDialog: FormDialog,
        callback: (v: boolean) => {
          if (v) window.location.reload()
        },
        props: {
          formId,
          edit: false
        }
      })
    })
  }

  copy(e: PointerEvent) {
    stopAndPrevent(e)

    const type = this.#getAttribute(e, 'data-type')
    const value = this.#getAttribute(e, 'data-value')

    Utils.copyText(value)

    window.avv_toast({
      message: window.localizeText(`forms.form_dialog.copy_${type}_success`)
    })
  }

  update(e: PointerEvent) {
    stopAndPrevent(e)

    const formId = this.#getAttribute(e, 'data-form-id')

    void this.#hydrateOrganisation().then(() => {
      window.avv_dialog({
        vueDialog: FormDialog,
        callback: (v: boolean) => {
          if (v) window.location.reload()
        },
        props: {
          formId,
          edit: true
        }
      })
    })
  }

  #getAttribute(event: Event, name: string) {
    return (event.currentTarget as HTMLElement).getAttribute(name) as string
  }

  #hydrateOrganisation() {
    return useOrganisationStore(getActivePinia()).hydrate({}, [
      'flippers_for_frontend'
    ])
  }
}

export default FormController
