import axios from 'axios'

let numClicks = 0
let singleClickTimer: NodeJS.Timeout

import { Controller } from 'stimulus'
import { IsHTMLElement } from '../features/dom_utils'

export default class extends Controller {
  private isClickAndHold: boolean | undefined
  private startX: number | null = null
  private startY: number | null = null
  canClose() {
    const requiredInputs = document.querySelectorAll(
      '.required-value-missing-on-backend'
    )

    if (requiredInputs.length > 0) {
      avv_toast({
        type: 'error',
        message: localizeText(
          'properties_sidebar.save_required_metadata_fields'
        )
      })
      return false
    }
    return true
  }

  fileViewerModalTrigger(event: Event) {
    const eventTarget = event?.target
    if(!IsHTMLElement(eventTarget)) return
    const isFileViewerModalTrigger = eventTarget?.classList?.contains(
      'file-viewer-modal-trigger'
    )
    if (isFileViewerModalTrigger)
      return document.querySelector(
        `tr[data-item-id="${eventTarget?.dataset?.itemId as string}"]`
      )
    return null
  }

  resolvePropertiesToggle(event: Event) {
    const eventTarget = event?.target
    if (this.isClickAndHold || !this.canClose() || !IsHTMLElement(eventTarget)) return

    const eventTrigger = (this.fileViewerModalTrigger(event) ||
      eventTarget?.closest('.event-trigger'))
    if (!IsHTMLElement(eventTrigger)) return

    const itemId = (eventTrigger.dataset.itemId) || (eventTrigger.dataset.folderId)
    const { itemType, docId, docParticipant, controller } =
      eventTrigger.dataset as {
        itemType: string
        docId: string
        docParticipant: string
        controller: string
      }
    numClicks++

    // distinguishes between a single click and a double click:
    if (numClicks === 1) {
      singleClickTimer = setTimeout(() => {
        numClicks = 0
        if (
          eventTarget.classList.contains('no-properties-link') ||
          eventTarget.closest('.no-properties-link')
        )
          return

        this.toggleBgColor(eventTrigger)
        let link
        if (itemType === 'custom-clause') {
          link = document.querySelector(`a[href="/${itemType}/${itemId}/edit"]`)
        } else {
          link = document.querySelector(`a[href="/${itemType?.split('-')[0]}/${itemId}/edit"]`)
        }
        if(!IsHTMLElement(link)) return
        link.click()
      }, 200)
    } else if (numClicks === 2) {
      if (
        eventTarget.classList.contains('no-properties-link') ||
        eventTarget.closest('.no-properties-link')
      )
        return
      clearTimeout(singleClickTimer)
      numClicks = 0
      if (eventTrigger.classList.contains('file-viewer-type')) {
        this.openFileViewer(event, itemId).catch((e: Error) => {
          avv_toast({
            type: 'error',
            message: e.message
          })
        })
        return
      }

      this.clickLink(itemType, docParticipant, docId, itemId, controller)
    }
  }

  clickLink(
    itemType: string,
    docParticipant: string,
    docId: string,
    itemId: string,
    controller: string
  ) {
    let link
    const isArchived = window.location.href.includes('archived')
      ? '&status=archived'
      : ''
    switch (itemType) {
      case 'folders':
        link = `/${controller}?folder_id=${itemId}${isArchived}`
        break
      case 'drive-file':
        link = `/drive/${itemId}`
        break
      case 'templates':
        link = `/templates/${itemId}/edit`
        break
      case 'custom-clauses':
        link = `/custom-clauses/${itemId}/edit`
      case 'custom-attributes':
        link = `/custom-attributes/${itemId}/edit`
      case 'drive-doc':
        link = docParticipant ? `/documents/${docId}/continue` : null
        break
    }
    if (link) window.location.href = link
    if (!link) {
      const docLink = document.querySelector(
        `a[data-doc-id="${docId}"]`
      )
      if(!IsHTMLElement(docLink)) return
      docLink.click()
    }
  }

  openFileViewer = async (
    event: Event,
    itemIdParam: string | null = null
  ): Promise<void> => {
    const itemId =
      itemIdParam || (event?.target as HTMLElement)?.dataset?.itemId

    if (!itemId) return

    this.closePropsSidebar()
    await new Promise((resolve) => setTimeout(resolve, 250))

    const url = `/drive/${itemId}/open_file_viewer`
    const response = await axios.get(url)
    const success = response.status.toString().startsWith('2')
    if (success) {
      const fileViewerModal = document.querySelector('#file-viewer-modal')
      if (fileViewerModal) fileViewerModal.innerHTML = response.data
    }
  }

  toggleBgColor(trigger: HTMLElement | null = null) {
    const elWithBlueBg = document.querySelector('.blue-tint-bg')

    if (elWithBlueBg) elWithBlueBg.classList.remove('blue-tint-bg')
    if (elWithBlueBg !== trigger && trigger)
      trigger.classList.add('blue-tint-bg')
  }

  handleMouseDown = (event: MouseEvent) => {
    this.isClickAndHold = false
    this.startX = event.clientX
    this.startY = event.clientY

    document.addEventListener('mousemove', this.handleMouseMove)
    document.addEventListener(
      'mouseup',
      (event) => {
        this.handleMouseUp(event).catch((e: Error) => {
          avv_toast({
            type: 'error',
            message: e.message
          })
        })
      },
      { once: true }
    )
  }

  handleMouseMove = (event: MouseEvent) => {
    if (this.startX !== null && this.startY !== null) {
      const deltaX = Math.abs(event.clientX - this.startX)
      const deltaY = Math.abs(event.clientY - this.startY)

      const draggingTolerance = 5

      if (deltaX > draggingTolerance || deltaY > draggingTolerance) {
        // If the mouse has moved beyond the draggingTolerance, consider it as dragging
        this.isClickAndHold = true
        this.startX = null
        this.startY = null

        document.removeEventListener('mousemove', this.handleMouseMove)
      }
    }
  }

  handleMouseUp = async (event: MouseEvent) => {
    const eventTarget = event.target
    if(!IsHTMLElement(eventTarget)) return
    const eventTrigger = eventTarget.closest('.event-trigger')
    if (!this.isClickAndHold) {
      if (eventTrigger?.classList.contains('file-viewer-type'))
        await this.openFileViewer(event)
      document.removeEventListener('mousemove', this.handleMouseMove)
    }
    this.isClickAndHold = true
  }

  closePropsSidebar = () => {
    const propsSidebarCloseBtnEl = <HTMLInputElement>(
      document.querySelector('.props-sidebar-close-icon')
    )
    propsSidebarCloseBtnEl?.click()
  }
}
